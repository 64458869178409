<template>
  <div class="login">
    <div class="login-logo-wrapper" :style="`backgroundColor: ${$config.primaryColor}`">
      <img :src="logo" />
    </div>
    <div class="login-content-wrapper">
      <!-- 注册表单 -->
      <van-form @submit="registerLogin" ref="registerForm" label-width="5em">
        <van-field
          v-model="registerFormData.surname"
          label="姓"
          name="surname"
          placeholder="请填写姓"
          :rules="registerRules.surname"
          clearable
        />
        <van-field
          v-model="registerFormData.name"
          :rules="registerRules.name"
          label="名"
          name="name"
          placeholder="请填写名"
          clearable
        />
        <van-field name="gender" label="性别">
          <template #input>
            <van-radio-group v-model="registerFormData.gender" direction="horizontal">
              <van-radio :name="1">男</van-radio>
              <van-radio :name="0">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="registerFormData.invite_code"
          :rules="registerRules.invite_code"
          readonly
          label="邀请码"
          name="invite_code"
          placeholder="请填写邀请码"
          clearable
        />
        <van-field
          v-model="registerFormData.mobile"
          :rules="registerRules.mobile"
          label="手机号"
          name="mobile"
          type="number"
          :maxlength="11"
          clearable
          placeholder="请填写手机号"
        />

        <van-field
          v-model="registerFormData.code"
          :rules="registerRules.code"
          center
          name="code"
          clearable
          label="验证码"
          placeholder="请填写验证码"
          type="number"
          :maxlength="4"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              class="send-code-button"
              native-type="button"
              @click="sendCode"
              :disabled="countdownTime < DEFAULT_COUNT_TIME"
              ><template v-if="countdownTime < DEFAULT_COUNT_TIME"> {{ countdownTime }} S 后重新获取 </template>
              <template v-else> 发送验证码 </template></van-button
            >
          </template>
        </van-field>

        <van-field
          v-model="registerFormData.password"
          :rules="registerRules.password"
          label="密码"
          name="password"
          autocomplete="new-password"
          placeholder="请填写密码"
          type="password"
          :maxlength="16"
          clearable
        />
        <van-button block type="primary" native-type="submit" class="submit-button">注 册</van-button>
      </van-form>
    </div>
    <div class="download-app">
      <router-link to="/download-app">下载APP（已注册用户）</router-link>
    </div>

    <div class="user-agreement">
      注册或登录代表您已同意 <router-link to="/user-agreement">《用户协议》</router-link>和
      <router-link to="/privacy-policy">《隐私声明》</router-link>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { RadioGroup, Radio, Form, Field, Button } from 'vant'
import api from '@/apis'

Vue.use(Form)
Vue.use(Field)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Button)

const validateMobile = (mobile) => /^1\d{10}$/.test(mobile)

// 性别
export const SEX = {
  MALE: 1,
  FEMALE: 0,
}

const DEFAULT_COUNT_TIME = 60 // 倒计时

const logomMap = {
  zstp: require('@/assets/images/logo_login.png'),
  mmjp: require('@/assets/images/logo_login_mmjp.png'),
  mmxp: require('@/assets/images/logo_login_mmxp.png'),
}

export default {
  name: 'registerer',
  data() {
    return {
      timer: null,
      DEFAULT_COUNT_TIME,
      countdownTime: DEFAULT_COUNT_TIME,
      // 注册表单数据
      registerFormData: {
        mobile: '',
        password: '',
        code: '',
        surname: '', // 姓
        name: '', // 名
        gender: SEX.MALE, // 性别
        invite_code: this.$route.query.invite_code || '', // 邀请码
        treeid: this.$route.query.tree_id || '',
      },
      registerRules: {
        surname: [
          {
            required: true,
          },
        ],
        name: [
          {
            required: true,
          },
        ],
        mobile: [
          {
            required: true,
          },
          {
            validator: function (value) {
              return validateMobile(value)
            },
            message: '请填写正确的手机号',
          },
        ],
        password: [
          {
            required: true,
          },
          {
            validator: function (value) {
              return value.length >= 8 && value.length <= 16
            },
            message: '长度在 8 到 16 个字符',
          },
        ],
        code: [
          {
            required: true,
          },
        ],
      },
    }
  },
  computed: {
    logo() {
      return logomMap[this.$config.projectCode]
    },
  },
  mounted() {},
  beforeDestroy() {
    this.timer && clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    async sendCode() {
      // 校验表单
      const res = await this.$refs.registerForm.validate('mobile')
      if (!res) {
        // 发送验证码
        await api.smsSend({
          mobile: this.registerFormData.mobile,
          event: 'register',
        })
        // 发送成功开始倒计时
        this.countdownTime = this.countdownTime - 1
        this.timer = setInterval(() => {
          if (this.countdownTime === 1) {
            this.countdownTime = DEFAULT_COUNT_TIME
            clearInterval(this.timer)
          } else {
            this.countdownTime = this.countdownTime - 1
          }
        }, 1000)
      }
    },
    // 注册
    async registerLogin(value) {
      const res = await api.loginRegister(this.registerFormData)
      this.$router.push({
        path: '/download-app',
        query: {
          registerSuccess: true,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  font-family: PingFang;
  background-color: #fffdf2;
  min-height: 100vh;
  .van-cell {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }
}
.login-logo-wrapper {
  height: 300px;
  background-color: #ac0404;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 280px;
    height: 200px;
  }
}

.login-content-wrapper {
  padding: 48px 40px;
  box-sizing: border-box;
}

.send-code-row {
  display: flex;
}

.send-code-button {
  display: flex;
  align-items: cener;
  justify-content: center;
  width: 220px;
  margin-left: 28px;
}

.login-registerer-forget-pswd {
  margin-top: 46px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  line-height: 1;

  .split {
    margin: 0 20px;
  }
}

.login-footer {
  position: fixed;
  bottom: 110px;
  left: 0;
  width: 100%;
  line-height: 1;
}

.login-other {
  font-size: 28px;
  text-align: center;
}

.login-other-icons {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  padding: 0 148px;

  image {
    height: 80px;
    width: 80px;
  }
}

.user-agreement {
  left: 0;
  width: 100%;
  font-size: 20px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;

  & a {
    color: #1358f5;
  }
}
.download-app {
  text-align: center;
  font-size: 32px;
}
.submit-button {
  margin-top: 60px;
}
</style>
